import React, { Suspense, lazy } from 'react';
import { AuthProvider } from './hooks/auth';
import { FlagProvider } from '@unleash/proxy-client-react';
import { CopilotUIProvider } from 'ds4-beta';
const DS4Switch = lazy(() => import('./DS4Switch'));

const config = {
  url: process.env.UNLEASH_PROXY_URL,
  clientKey: process.env.UNLEASH_CLIENT_KEY,
  appName: 'copilot-admin',
  disableRefresh: true,
};

const Root = () => (
  <Suspense fallback={<></>}>
    <FlagProvider config={config}>
      <AuthProvider>
        <CopilotUIProvider>
          <DS4Switch />
        </CopilotUIProvider>
      </AuthProvider>
    </FlagProvider>
  </Suspense>
);
export default Root;
